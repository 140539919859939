import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { CustomAlert } from "../Utils/CustomAlert";
import React, { createContext } from "react";

export const AlertsContext = createContext(undefined);

const Alerts = forwardRef((props, ref) => {
    const [alerts, setAlerts] = useState([]);

    useImperativeHandle(ref, () => ({
        show: (toastOptionsWithId) => {
            setAlerts((state) => {
                const clone = [...state];
                clone.push(toastOptionsWithId);
                if (props.limit && clone.length > props.limit) {
                    clone.shift();
                }
                return clone;
            });

            if (toastOptionsWithId.autoClose) {
                setTimeout(() => {
                    setAlerts((state) => state.filter((t) => t.id !== toastOptionsWithId.id));
                }, toastOptionsWithId.autoClose);
            }
        },

        hide: (id) => {
            setAlerts((state) => [...state].filter((t) => t.id !== id));
        },
    }));

    return (
        <div style={{
            position: "fixed",
            top:      "1rem",
            right:    "1rem",
            zIndex:   1050,
        }}>
            {alerts.map((alert) => {
                return (
                    <CustomAlert
                        key={alert.id}
                        id={alert.id}
                        onClose={() => {
                            setAlerts((alertsState) => alertsState.filter((t) => t.id !== alert.id));
                            return alert?.onClose?.();
                        }}
                        title={alert.title}
                        body={alert.body}
                        variant={alert.variant}
                    />
                );
            })}
        </div>
    );
});

const AlertProvider = ({
    children,
    limit = 5,
}) => {
    const alertsRef = useRef(null);

    return (
        <AlertsContext.Provider value={alertsRef}>
            {children}
            <Alerts ref={alertsRef} limit={limit}></Alerts>
        </AlertsContext.Provider>
    );
};

export default AlertProvider;
