import React from "react";
import { useEffect } from "react";
import { Alert } from "react-bootstrap";

export function CustomAlert({ title, id, onClose, variant, body }) {
    return <Alert key={id} variant={variant} style={{
        minWidth: "280px",
        maxWidth: "320px",
    }}>
        <div className="d-flex justify-content-between aling-items-center">
            <strong className="mr-3">{ title}</strong>
            <button className="close" onClick={onClose}>x</button>
        </div>
        <p className="m-0">{body}</p>
    </Alert>;
}
